import { createContext, useState, useMemo } from 'react';

import type { Network, User, Admin } from 'hb-react/types';

type SetContextStateAction<T> = (value: Partial<T>) => void;

export const getCurrentUrl = () => {
  if (typeof window !== 'undefined') {
    return { origin: window.location.origin };
  }

  return {};
};

export const CurrentUserContext = createContext<{
  currentUser: User | null;
  updateCurrentUser: (user: Partial<User>) => void;
}>({
  currentUser: null as User | null,
  updateCurrentUser: () => {},
});

export const CurrentAdminContext = createContext<{
  currentAdmin: Admin | null;
  updateCurrentAdmin?: (admin: Partial<any>) => void;
}>({
  currentAdmin: null as Admin | null,
  updateCurrentAdmin: () => {},
});

export const CurrentNetworkContext = createContext<{
  currentNetwork: Network;
  currentNetworkId?: number;
  updateCurrentNetwork: SetContextStateAction<Network>;
}>({
  currentNetwork: {} as Network,
  currentNetworkId: undefined,
  updateCurrentNetwork: () => {},
});

export const CurrentUrlContext = createContext<{
  currentUrl: Partial<{ origin: string }>;
  updateCurrentUrl: SetContextStateAction<{ origin: string }>;
}>({
  currentUrl: {},
  updateCurrentUrl: () => {},
});

export const CurrentSessionContext = createContext<{
  currentSession: any;
  updateCurrentSession: SetContextStateAction<any>;
}>({
  currentSession: {},
  updateCurrentSession: () => {},
});

export const CurrentNetworkProvider = ({
  children,
  currentNetwork: initialCurrentNetwork = {} as Network,
  currentNetworkId: initialCurrentNetworkId,
}: {
  children: React.ReactNode;
  currentNetwork?: Network;
  currentNetworkId?: number;
}) => {
  const [currentNetwork, setCurrentNetwork] = useState(
    () => initialCurrentNetwork,
  );

  const [currentNetworkId, setCurrentNetworkId] = useState(
    initialCurrentNetwork?.id ?? initialCurrentNetworkId,
  );

  const value = useMemo(
    () => ({
      currentNetwork,
      currentNetworkId,
      updateCurrentNetwork: (network) => {
        setCurrentNetwork((prev) => ({ ...prev, ...network }));
        setCurrentNetworkId(network?.id);
      },
    }),
    [currentNetwork, currentNetworkId],
  );

  if (
    initialCurrentNetwork?.id &&
    initialCurrentNetworkId &&
    initialCurrentNetwork.id !== initialCurrentNetworkId
  ) {
    throw new Error('currentNetwork and currentNetworkId are not the same');
  }

  return (
    <CurrentNetworkContext.Provider value={value}>
      {children}
    </CurrentNetworkContext.Provider>
  );
};

export const CurrentUserProvider = ({
  children,
  currentUser: initialCurrentUser,
}) => {
  const [currentUser, setCurrentUser] = useState(() => initialCurrentUser);

  const value = useMemo(
    () => ({
      currentUser,
      updateCurrentUser: (user) =>
        setCurrentUser((prev) => ({ ...prev, ...user })),
    }),
    [currentUser],
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const CurrentAdminProvider = ({
  children,
  currentAdmin: initialCurrentAdmin,
}) => {
  const [currentAdmin, setCurrentAdmin] = useState(() => initialCurrentAdmin);

  const value = useMemo(
    () => ({
      currentAdmin,
      updateCurrentAdmin: (admin) =>
        setCurrentAdmin((prev) => ({ ...prev, ...admin })),
    }),
    [currentAdmin],
  );

  return (
    <CurrentAdminContext.Provider value={value}>
      {children}
    </CurrentAdminContext.Provider>
  );
};

export const CurrentUrlProvider = ({
  children,
  currentUrl: initialCurrentUrl,
}) => {
  const [currentUrl, setCurrentUrl] = useState(() => initialCurrentUrl);

  const value = useMemo(
    () => ({
      currentUrl,
      updateCurrentUrl: (url) => setCurrentUrl(url),
    }),
    [currentUrl],
  );

  return (
    <CurrentUrlContext.Provider value={value}>
      {children}
    </CurrentUrlContext.Provider>
  );
};

export const CurrentSessionProvider = ({
  children,
  currentSession: {
    has_linkedin_oauth2_token: hasLinkedinOauth2Token,
    linkedin_oauth2_token_expires_at: linkedinOauth2TokenExpiresAt,
    check_admin_dual_account: checkAdminDualAccount,
    network_admin: networkAdmin,
    display_linkedin_import: displayLinkedinImport,
    user_can_target_users_from_messaging: userCanTargetUsersFromMessaging,
  },
}) => {
  const [currentSession, setCurrentSession] = useState({
    hasLinkedinOauth2Token,
    linkedinOauth2TokenExpiresAt,
    checkAdminDualAccount,
    networkAdmin,
    displayLinkedinImport,
    userCanTargetUsersFromMessaging,
  });

  const value = useMemo(
    () => ({
      currentSession,
      updateCurrentSession: (session) => setCurrentSession(session),
    }),
    [currentSession],
  );

  return (
    <CurrentSessionContext.Provider value={value}>
      {children}
    </CurrentSessionContext.Provider>
  );
};

const Provider = ({
  children,
  currentNetwork,
  currentNetworkId,
  currentUser,
  currentAdmin,
  currentUrl,
  currentSession,
}) => (
  <CurrentNetworkProvider
    currentNetwork={currentNetwork}
    currentNetworkId={currentNetworkId}
  >
    <CurrentUrlProvider currentUrl={currentUrl}>
      <CurrentAdminProvider currentAdmin={currentAdmin}>
        <CurrentUserProvider currentUser={currentUser}>
          <CurrentSessionProvider currentSession={currentSession}>
            {children}
          </CurrentSessionProvider>
        </CurrentUserProvider>
      </CurrentAdminProvider>
    </CurrentUrlProvider>
  </CurrentNetworkProvider>
);

Provider.defaultProps = {
  currentNetwork: {},
  currentNetworkId: undefined,
  currentUser: null,
  currentAdmin: null,
  currentUrl: getCurrentUrl(),
  currentSession: {},
};

export default { Provider };
