import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

/**
 * @deprecated please use `import { Loader } from '@hivebrite/design-system/Loader'` instead
 */
const Loader = ({ full, small, fillParent, wrapperHtml }) => {
  if (full) {
    return <Full wrapperHtml={wrapperHtml} />;
  }
  if (fillParent) {
    return <FillParent wrapperHtml={wrapperHtml} />;
  }

  return <Icon wrapperHtml={wrapperHtml} small={small} />;
};

Loader.propTypes = {
  fillParent: PropTypes.bool,
  full: PropTypes.bool,
  small: PropTypes.bool,
};

Loader.defaultProps = {
  fillParent: false,
  full: false,
  small: true,
};

const Full = ({ wrapperHtml }) => (
  <div
    className={classNames(
      'transition-all-300 on-top--more absolute one-whole height-100-percent text--center',
      get(wrapperHtml, 'classNames', ''),
    )}
    title={__('Loading...')}
  >
    <div className="height-100-percent flexbox">
      <div className="flexbox__item v-middle">
        <Icon small={false} />
      </div>
    </div>
  </div>
);

const FillParent = ({ wrapperHtml }) => (
  <div
    className={classNames(
      'loader__fill-parent',
      get(wrapperHtml, 'classNames', ''),
    )}
  >
    <Icon small={false} />
  </div>
);

const Icon = ({ small, wrapperHtml }) => (
  <div
    data-testid="loader"
    className={classNames(
      'loader',
      { 'loader--half': small },
      get(wrapperHtml, 'classNames', ''),
    )}
  >
    <div className="custom-bg-color-header double-bounce1" />
    <div className="custom-bg-color-outline-navigation double-bounce2" />
    <div className="custom-bg-color-button-primary double-bounce3" />
  </div>
);

Loader.defaultProps = {
  full: false,
  small: true,
  wrapperHtml: {
    classNames: '',
  },
};

Loader.propTypes = {
  full: PropTypes.bool,
  small: PropTypes.bool,
  fillParent: PropTypes.bool,
  wrapperHtml: PropTypes.object,
};

export default Loader;
