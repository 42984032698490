/* eslint-disable react/jsx-filename-extension */

import { get, set } from 'lodash';
import { forwardRef, Suspense } from 'react';

const lazyLoadGlobalComponents = (components) =>
  Object.keys(components).forEach((componentPath) => {
    const Component = get(components, componentPath);

    if (Component) {
      set(
        window,
        componentPath,
        forwardRef((props, ref) => (
          <Suspense fallback={null}>
            <Component ref={ref} {...props} />
          </Suspense>
        )),
      );
    }
  });

export default lazyLoadGlobalComponents;
