const locationDataStructure = [
  'address',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'city',
  'colloquial_area',
  'country',
  'country_code',
  'lat',
  'lng',
  'location',
  'neighborhood',
  'postal_code',
  'sublocality_level_1',
];

export const getFormattedLocation = (locationValues) =>
  locationDataStructure.reduce((result, attribute) => {
    result[attribute] = locationValues?.[attribute] || '';

    return result;
  }, {});
