export function sortFields(fieldIds, fieldBlock) {
  const sortedFields = mainFieldsOrder()[fieldBlock];

  const orderedFields = fieldIds
    .filter((field) => Boolean(sortedFields[field]))
    .sort((a, b) => sortedFields[a] - sortedFields[b]);

  return [
    ...orderedFields,
    ...fieldIds.filter((field) => !sortedFields[field]),
  ];
}

export function mainFieldsOrder() {
  return {
    main: {
      honorary_title: 1,
      gender: 2,
      prefix_firstname: 3,
      firstname: 4,
      prefix_name: 5,
      lastname: 6,
      suffix_name: 7,
      maidenname: 8,
      headline: 9,
      birthday: 10,
      birthplace: 11,
      membership_status: 12,
    },
  };
}
