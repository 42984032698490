const Loader = {
  start : function(opts) {
    opts = opts === undefined ? {} : opts

    $('body').prepend('' +
        '<a id=loader class="fa fa-spinner fa-spin icon-size--400" ' +
          'style="color:' + opts.color  + ';"' +
        ' ></a>');
  },
  stop : function() {$('#loader').remove();}
};

export default Loader;