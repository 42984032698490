import jwtDecode from 'jwt-decode';

import { fetchUserJwt } from 'hb-react/frontoffice/utils/user';

class WebsocketClient {
  constructor(uri, options) {
    this.uri = uri;
    this.options = options;
    this.ws = null;
    this.wsState = {
      ready: false,
    };
    this.topics = [];
    this.topics_receive_message = {};
    this.token = null;
  }

  async subscribe(topics = []) {
    const setToken = async () => {
      this.token = await fetchUserJwt().then(({ token }) => token);
    };

    const connection = () => {
      const uri = new URL(this.uri);

      this.topics = topics;
      this.ws = new WebSocket(uri.href, ['jwt', this.token]);

      this.ws.onopen = () => {
        this.wsState.ready = true;
        topics.forEach((topic) => {
          this.join(topic);
        });
      };

      this.ws.onmessage = (event) => {
        this.dispatch_message(event, this);
      };

      this.ws.onclose = () => {
        this.wsState.ready = false;
        this.subscribe(this.topics);
      };
    };

    if (this.token) {
      const jwt = jwtDecode(this.token);

      const currentTime = Date.now().valueOf() / 1000;

      // Only renew the JWT if is expired
      if (jwt.exp && currentTime > jwt.exp) {
        await setToken();
      }
    } else {
      await setToken();
    }

    connection();
  }

  join(topic) {
    const msg = {
      action: 'join',
      topic,
    };

    this.ws.send(JSON.stringify(msg));
  }

  leave(topic) {
    const msg = {
      action: 'leave',
      topic,
    };

    this.topics_receive_message[topic] = null;

    this.ws.send(JSON.stringify(msg));
  }

  close() {
    this.ws.close();
  }

  // eslint-disable-next-line class-methods-use-this
  dispatch_message(event, handler) {
    const msg = JSON.parse(event.data);
    const callback_func = handler.topics_receive_message[msg.topic];

    if (callback_func !== undefined) {
      callback_func(msg.payload);
    }
  }
}

export default new WebsocketClient(window.__HB_ENV__.WEBSOCKET_WS_URI);
