/* eslint-disable no-undef */
/* eslint-disable jquery/no-ready */
$(document).ready(() => {
  let fancyboxCheckInterval;

  const configureFancybox = () => {
    if ($?.fancybox?.defaults) {
      $.fancybox.defaults.padding = 0;
      $.fancybox.defaults.overlayOpacity = 0.45;
      $.fancybox.defaults.overlayColor = '#000';
      $.fancybox.defaults.scrolling = 'no';
      $.fancybox.defaults.autoScale = false;
      $.fancybox.defaults.autoSize = true;
      $.fancybox.defaults.minWidth = 400;
      $.fancybox.defaults.enableEscapeButton = false;

      const fancy = {};

      fancy.oldHeight = 0;
      fancy.$el = $('#fancybox-wrap');
      if (fancy.$el.length) {
        fancy.$el.unbind('mousewheel'); // Hack to restore fancybox scroll (confllict with jquery.datetimepicker)
      }

      return true;
    }

    return false;
  };

  // Hack to solve Sentry error "Cannot read properties of null (reading 'defaults')"
  // Our script seems to be executed before of the fancybox library
  if (!configureFancybox()) {
    let attempts = 0;

    fancyboxCheckInterval = setInterval(() => {
      if (configureFancybox() || attempts >= 50) {
        clearInterval(fancyboxCheckInterval);
      }
      attempts++;
    }, 500);
  }
});
