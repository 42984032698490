export const enableScroll = (active = true) => {
  if (active == true) {
    $('body').removeClass('scroll-desactivated')
  }
  else {
    $('body').addClass('scroll-desactivated')
  }

}

export const scrollTop = ({ smooth } = {}) => {
  if ($('#main-container')[0]) {
    // FrontOffice
    smooth
      ? $('#main-container')[0].scrollIntoView({ behavior: 'smooth' })
      : $('#main-container')[0].scrollIntoView();
  } else if ($('.header-container')[0]) {
    // PublicOffice
    smooth
      ? $('.header-container')[0].scrollIntoView({ behavior: 'smooth' })
      : $('.header-container')[0].scrollIntoView();
  }
};
