// Used for old Companies typehead/bloodhound
// to remove with autocomplete_companies

const AutocompleteTemplate = ({
  id,
  inline_location: inlineLocation,
  logo_url: logoUrl,
  name,
  short_description: shortDescription,
}) => (
  <div className="search-item soft-quarter--ends hard--sides">
    <div className="grid grid--full soft-half--left">
      <div
        className="inline-block text--center v-middle"
        style={{ width: '32px' }}
      >
        {logoUrl ? (
          <img src={logoUrl} height="32px" width="32px" alt="" />
        ) : (
          <i
            className="icon-building icon-size--200"
            data-testid="icon-fallback"
          />
        )}
      </div>
      <div className="grid__item color-gray soft-half--left v-middle">
        <div className="weight--bold">
          {id ? name : `${I18n.t('web.companies.add_new_company')}: ${name}`}
        </div>
        {shortDescription && <div>{shortDescription}</div>}
        {inlineLocation && (
          <div className="f-size--small">{inlineLocation}</div>
        )}
      </div>
    </div>
  </div>
);

export default AutocompleteTemplate;
