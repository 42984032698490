export const showAroundMeButton = () => {
  if (
    AlumniConnect.mobile.includes('aroundme') &&
    $('.js-aroundme-anchor').data('can-display')
  ) {
    $('.js-aroundme-anchor').show();
  }
};

export const hideAroundMeButton = () => {
  $('.js-aroundme-anchor').hide();
};

export const enableAroundMeButton = () => {
  $('.js-aroundme-anchor').data('can-display', true);
  $('.js-aroundme-anchor').attr('data-can-display', true);
  showAroundMeButton();
};

export const disableAroundMeButton = () => {
  $('.js-aroundme-anchor').removeData('can-display');
  $('.js-aroundme-anchor').removeAttr('data-can-display');
  hideAroundMeButton();
};
