/* eslint-disable class-methods-use-this */
import Search from './search';
import { formatBingType } from '../utils/locationTypes';

// Bloodhound
require('corejs-typeahead');

class BingSearch extends Search {
  source() {
    const token = window.__HB_ENV__.BING_GEOCODER_API_KEY;
    const url = `https://dev.virtualearth.net/REST/v1/Locations?incl=ciso2&maxResults=5&q=%QUERY&key=${token}`;
    const engine = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url,
        wildcard: '%QUERY',
        transform: (response) =>
          // When selecting locations without a country, there was no reference point for distance calculations,
          // causing the system to show all users. We limit suggestions to countries and deeper locations by default.
          // However, some sensitive communities (e.g. health support groups) need anonymity and the ability to use
          // wide locations (oceans, continents etc). The anonymousLocations flag enables this broader location support.
          response.resourceSets[0].resources.filter(
            (r) =>
              ['PopulatedPlace', 'Address'].indexOf(r.entityType) > -1 &&
              (this.anonymousLocations ||
                (r.address.countryRegion != null &&
                  r.address.countryRegion.trim() !== '')),
          ),
      },
    });

    engine.initialize();

    return engine.ttAdapter();
  }

  suggestionDisplay(data) {
    if (this.anonymousLocations) {
      return data.address.formattedAddress;
    }
    const type = formatBingType(data.entityType);

    return `${data.address.formattedAddress} (${type})`;
  }

  data(data) {
    const bounds = data.bbox || {};
    // Formatted bounds must looks like
    // [
    //   [northEastLat, northEastLng],
    //   [southWestLat, southWestLng],
    // ]
    const formattedBounds = [
      [bounds?.[2], bounds?.[3]],
      [bounds?.[0], bounds?.[1]],
    ];

    return {
      city: data.address.locality || data.address.adminDistrict2,
      country: data.address.countryRegion,
      country_code: data.address.countryRegionIso2,
      location_level: null,
      lat: data.geocodePoints[0].coordinates[0],
      lng: data.geocodePoints[0].coordinates[1],
      bounds: formattedBounds,
    };
  }
}

export default BingSearch;
