// Fallback to another locale if we doesn't have it
const fallbackLocales = {
  'zh-Hant': 'zh-CN',
  'zh-Hans': 'zh-CN',
};

// Don't load any locale for their locales
const omitLocales = ['en', 'deepbloo'];

const getLocale = locale => {
  if (omitLocales.includes(locale)) {
    return null;
  }

  return fallbackLocales[locale] ?? locale;
};

export default getLocale;
