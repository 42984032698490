import moment from 'moment-timezone';

export const fewMonthsAgo = (numberOfMonths, date = new Date()) =>
  new Date(date.setMonth(date.getMonth() - numberOfMonths)).toUTCString();

export const getTimezoneId = (allTimezones, receivedTimezone) => {
  const selectedTimezone = allTimezones?.find(
    (timezone) =>
      receivedTimezone === timezone.id ||
      receivedTimezone === timezone.tzinfo_name,
  );

  return selectedTimezone?.id || '';
};

export const getFormattedDate = (date) => {
  if (date) {
    return moment.parseZone(date).format('YYYY-MM-DD'); // use parseZone to ignore timezone from date
  }

  return date;
};
