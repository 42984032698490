import type { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line no-restricted-imports -- this an exception for axios import
import axios from 'axios';
import qs from 'qs';

import { isMobileApp } from 'hb-react/shared/utils/mobile';
import getCsrfToken from 'helpers/getCsrfToken';

import { configToSnakeCase, responseToCamelCase } from './axiosInterceptors';

const baseConfig: AxiosRequestConfig = {
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getCsrfToken(),
    ...(isMobileApp() && {
      'X-Hivebrite-Mobile-App': isMobileApp().toString(),
    }),
  },
  paramsSerializer: (params: any) =>
    qs.stringify(params, { arrayFormat: 'brackets' }),
};

export const axiosInstance = axios.create(baseConfig);

export const { isCancel, isAxiosError } = axios;

export const axiosWithSyntaxHandlers = axios.create(baseConfig);

axiosWithSyntaxHandlers.interceptors.request.use((config) =>
  configToSnakeCase(config),
);

axiosWithSyntaxHandlers.interceptors.response.use((response) =>
  responseToCamelCase(response),
);
