import moment from 'moment';
import momentTZ from 'moment-timezone';

import getLocale from './getLocale';

// Dynamic load moment locale for the whole project
(async () => {
  try {
    let locale = getLocale(I18n.locale);

    if (locale) {
      // Moment need locale in lowercase... but not for en-SG, well.
      if (locale !== 'en-SG') {
        locale = locale.toLowerCase();
      }

      await import(`moment/locale/${locale}`);
      moment.locale(locale);

      // Workaround from https://github.com/moment/moment-timezone/issues/647
      momentTZ.defineLocale(locale, moment.localeData()._config); // copy locale to moment-timezone
      momentTZ.locale(locale); // apply it to moment-timezone
    }
  } catch (e) {} // eslint-disable-line no-empty
})();
