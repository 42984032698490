export const MIN_LENGTH = 2;

class Search {
  constructor(params) {
    // eslint-disable-next-line no-undef
    this.$container = $(params.container);
    this.onlineEnabled = params.onlineEnabled;
    this.onlineLabel = params.onlineLabel;
    this.region = params.region;
    this.anonymousLocations = params.anonymousLocations;
    this.init();
  }

  static isReady() {
    return true;
  }

  async init() {
    const source = await this.source();

    const dataSources = [];

    if (this.onlineEnabled) {
      dataSources.push({
        source: (query, syncResults) => {
          if (!query) {
            syncResults([this.onlineLabel]);
          }
        },
        hint: true,
        templates: {
          suggestion: (data) =>
            `<div class="dropdown__item soft-half cursor-pointer">
              ${data}
            </div>`,
          notFound: null,
        },
      });
    }
    dataSources.push({
      source: (query, syncResults, asyncResults) => {
        if (source && query.length >= MIN_LENGTH) {
          return source(query, this.region, syncResults, asyncResults);
        }
      },
      display: this.suggestionDisplay,
      hint: true,
      templates: {
        suggestion: (data) =>
          `<div class="dropdown__item soft-half cursor-pointer">
            ${this.suggestionDisplay(data)}
          </div>`,
        notFound: `<div class="dropdown__item soft-half cursor-pointer tt-suggestion">${I18n.t(
          'errors.messages.not_found',
        )}</div>`,
      },
    });

    this.$container.typeahead(
      {
        highlight: false,
        // In order to display the "online" option as soon as the input is focused we must
        // leave this to 0. However we manually implement a minLength on the async dataset
        // to maintain the original behavior (see MIN_LENGTH below and
        // in app/assets/javascripts/react/frontoffice/components/inputs/InputLocation/index.jsx)
        minLength: 0,
        classNames: {
          menu: 'dropdown-menu dropdown-menu--full',
          dataset: 'tt-dataset',
          cursor: 'tt-selected',
        },
      },
      ...dataSources,
    );
  }
}

export default Search;
