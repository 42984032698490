import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, pick } from 'lodash';
import React, { useMemo } from 'react';
import type { ValueOf } from 'ts-essentials';

import { ALL_FLAGS_STORE, getFlag } from 'hb-react/shared/utils/launchDarkly';

import flagsList, { flagsIM } from './flags';
import legacyFlagsList from './legacyFlags';

let LOG_DISPLAYED = false;

const LOG = (flags) => {
  if (!LOG_DISPLAYED) {
    // eslint-disable-next-line no-console
    console.groupCollapsed(
      '%cFlags list',
      'display: inline-block; background-color: #1c79c0; color: #fff; padding: 3px; border-radius: 3px;',
    );

    const listFlags = Object.entries(flags)
      .map(([key, value]) => ({
        name: key,
        value,
      }))
      // @ts-expect-error: types
      .sort((a, b) => b.value - a.value);

    listFlags.forEach(({ name, value }) =>
      // eslint-disable-next-line no-console
      console.log(`%c${name} => ${value}`, `color: ${value ? 'green' : 'red'}`),
    );

    // eslint-disable-next-line no-console
    console.groupEnd();

    LOG_DISPLAYED = true;
  }
};

const arrayToFlags = ({ arr, flags }) =>
  arr.reduce((result, key) => {
    result[key] = getFlag({
      [key]: flags[key],
    });

    return result;
  }, {});

export type FlagNames = ValueOf<typeof flagsList>;

const useFeatureFlag = (
  getter?: (values: any) => void,
): Record<FlagNames | 'flagsLoaded', boolean> => {
  const flags = useFlags();

  // dev/test use ALL_FLAGS_STORE while prod uses flags
  const flagsLoaded = ALL_FLAGS_STORE.items !== null || !isEmpty(flags);

  return useMemo(() => {
    const legacyFlags = legacyFlagsList(flags as any);
    const newFlags = arrayToFlags({
      arr: flagsList,
      flags,
    });

    const values = {
      flagsLoaded,
      ...legacyFlags.values,
      ...newFlags,
    };

    // Display flags list in console only for dev/test env
    if (ALL_FLAGS_STORE.items !== null) {
      LOG({
        ...arrayToFlags({
          arr: legacyFlags.flags,
          flags,
        }),
        ...newFlags,
      });
    }

    return typeof getter === 'function' ? getter(values) : values;
  }, [flags, flagsLoaded, getter]);
};

export const withFeatureFlag = (Component) =>
  React.forwardRef((props, ref) => {
    const contextValue = useFeatureFlag();

    return React.createElement(Component, {
      flags: contextValue,
      ...props,
      ref,
    });
  });

export function useMessagingFeatureFlag() {
  const flags = useFeatureFlag();

  return useMemo(() => pick(flags, ...flagsIM), [flags]);
}

export default useFeatureFlag;
