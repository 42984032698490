import { axiosInstance as axios } from 'hb-react/shared/utils/axios';

export const fetchUserJwt = () =>
  axios
    .get(Routes.user_jwt_path(), { credentials: 'same-origin' })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }

      return response.data;
    });

export default fetchUserJwt;
