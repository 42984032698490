import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  StyleSheetManager,
} from 'styled-components';

import { createTheme, FONT_BRIGHTNESS } from '@hivebrite/design-system/Theme';

import { useDefaultAppContexts } from 'hb-react/frontoffice/contexts/withDefaultAppContexts';

import { convertNetworkColorsToFrontofficeTheme } from './convertNetworkColorsToFrontofficeTheme';
import { convertNetworkThemingToFrontofficeThemeVariables } from './convertNetworkThemingToFrontofficeThemeVariables';
import { shouldForwardProp } from './shouldForwardProp';

const ThemeProvider = ({
  children,
  builderCustomBreakpoints = undefined,
  builderDisableStyledSystemCache = false,
  theme: themeName,
  options = {},
  fontBrightness,
}) => {
  // Colors & Theming automatic fallback if inside DefaultApp contexts
  let networkColors;
  let networkTheming;
  const { currentNetwork } = useDefaultAppContexts();

  switch (themeName) {
    case 'fo':
    case 'po':
      networkColors = options?.customNetworkColors || currentNetwork?.colors;
      networkTheming = options?.customNetworkTheming || currentNetwork?.theming;
      break;
    case 'bo':
    default:
  }

  const currentTheme = useMemo(() => {
    let customColors = {};
    let customThemeVariables = {};

    if (themeName === 'fo' || themeName === 'po') {
      customColors = convertNetworkColorsToFrontofficeTheme(networkColors);
      customThemeVariables = convertNetworkThemingToFrontofficeThemeVariables({
        theming: networkTheming,
        fontBrightness,
      });

      return createTheme({
        builderCustomBreakpoints,
        customColors,
        customThemeVariables,
        builderDisableStyledSystemCache,
        themeName,
      });
    }
    if (themeName === 'bo') {
      // The BO _never_ use customColors or customThemeVariables ; it's the "naked" HB theme
      return createTheme({
        builderCustomBreakpoints,
        builderDisableStyledSystemCache,
        themeName,
      });
    }
  }, [
    builderCustomBreakpoints,
    builderDisableStyledSystemCache,
    themeName,
    fontBrightness,
    networkColors,
    networkTheming,
  ]);

  return (
    <StyledThemeProvider theme={currentTheme}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        {typeof children === 'function'
          ? children({ theme: currentTheme })
          : children}
      </StyleSheetManager>
    </StyledThemeProvider>
  );
};

ThemeProvider.propTypes = {
  theme: PropTypes.oneOf(['fo', 'po', 'bo']).isRequired,
  fontBrightness: PropTypes.oneOf([
    FONT_BRIGHTNESS.DARK,
    FONT_BRIGHTNESS.LIGHT,
  ]),
  options: PropTypes.shape({
    customNetworkColors: PropTypes.object,
  }),
};

export default ThemeProvider;
