window.AlumniConnect.Helpers.Linkedin = {
  openAuthWindow(url, title, w, h) {
    if (title == null) {
      title = '';
    }
    if (w == null) {
      w = 400;
    }
    if (h == null) {
      h = 500;
    }

    const newWindow = window.open(
      url,
      title,
      `scrollbars=yes, width=${w}, height=${h}scrollbars=0,location=0,menubar=0,resizable=0,status=0,titlebar=0,toolbar=0`,
    );

    if (window.focus) {
      return newWindow.focus();
    }
  },
  buildAuthUrl(path) {
    const host = new URL(window.__HB_ENV__.HOST);

    host.hostname = `${AlumniConnect.currentNetwork?.identifier}.${
      host.hostname
    }`;
    const url = new URL(host.origin + path);

    return decodeURIComponent(url);
  },
};
