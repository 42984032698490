import he from 'he';

export function htmlSafe(value) {
  // Only sanitize string
  if (typeof value === 'string') {
    return he.encode(he.decode(value));
  }

  return value;
}

export function htmlDecode(input) {
  const e = document.createElement('textarea');

  e.innerHTML = input;

  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}
