const COUNTRIES_WITH_STATE = ['AU', 'CA', 'MX', 'US'];

export const completePostalAddress = (postalAddress, countries) => {
  const postalValues = [
    postalAddress?.address_1,
    postalAddress?.address_2,
    postalAddress?.address_3,
    postalAddress?.city,
    COUNTRIES_WITH_STATE.includes(postalAddress?.country)
      ? postalAddress?.state
      : null,
    postalAddress?.postal_code,
    countries ? countries?.[postalAddress?.country] : postalAddress?.country,
  ];

  return postalValues.filter(Boolean).join(', ');
};

export const partialPostalAddress = (postalAddress, countries) => {
  const postalValues = [
    postalAddress?.address_1,
    postalAddress?.city,
    COUNTRIES_WITH_STATE.includes(postalAddress?.country)
      ? postalAddress?.state
      : null,
    postalAddress?.postal_code,
    countries ? countries?.[postalAddress?.country] : postalAddress?.country,
  ];

  return postalValues.filter(Boolean).join(', ');
};

export const partialLocation = (location) => {
  const address = location?.address;
  let city = location?.city;
  const state = location?.administrative_area_level_1;
  const country = location?.country;
  const locationCanDisplayState =
    COUNTRIES_WITH_STATE.includes(location?.country_code) && state;

  if (!city && locationCanDisplayState) {
    city = location?.sublocality_level_1;
  }

  const locationWithCityStateCountry = s__(
    'locationWithCityStateCountry|%{city}, %{state}, %{country}',
  ).i__({
    city,
    state,
    country,
  });
  const locationWithCityCountry = s__(
    'locationWithCityCountry|%{city}, %{country}',
  ).i__({
    city,
    country,
  });
  const locationWithStateCountry = s__(
    'locationWithStateCountry|%{state}, %{country}',
  ).i__({
    state,
    country,
  });

  let partialLocation = city || '';

  if (!city && !country) {
    partialLocation = address || '';
  } else if (locationCanDisplayState) {
    if (city) {
      partialLocation = locationWithCityStateCountry;
    } else {
      partialLocation = locationWithStateCountry;
    }
  } else if (city && country) {
    partialLocation = locationWithCityCountry;
  } else if (country) {
    partialLocation = country;
  }

  return partialLocation;
};
