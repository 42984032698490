import { camelCase, snakeCase } from 'lodash';

import { TO_NOT_TRANSFORM, TO_ALWAYS_TRANSFORM } from './constants';

export const configToSnakeCase = (config) => {
  const { params, data } = config;

  return {
    ...config,
    params: keysSyntaxModifier(snakeCase, params),
    data: keysSyntaxModifier(snakeCase, data),
  };
};

export const responseToCamelCase = (response) => {
  const { data } = response;

  return {
    ...response,
    data: keysSyntaxModifier(camelCase, data),
  };
};

// https://hivebrite.atlassian.net/browse/PS-11494
// custom fields have other prefixes that needed to be included in this regex
const isCustomAttribute = (key) => /^(gte_|lte_|min__|max__|_).*/.test(key);

const shouldSkipKeySyntaxModifier = (key) => {
  if (TO_ALWAYS_TRANSFORM.includes(key)) {
    return false;
  }

  return isCustomAttribute(key) || TO_NOT_TRANSFORM.includes(key);
};

const keysSyntaxModifier = (syntaxModifier, object) => {
  // Recursive function to pass through each nested object to change keys syntax
  const appliedSyntaxToObject = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => appliedSyntaxToObject(v));
    }
    if (isFormData(obj)) {
      const modifiedFormData = new FormData();

      Array.from(obj).forEach(([key, value]) => {
        modifiedFormData.append(
          syntaxModifier(key),
          keysSyntaxModifier(syntaxModifier, value),
        );
      });

      return modifiedFormData;
    }
    if (isADefinedObject(obj)) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [shouldSkipKeySyntaxModifier(key) ? key : syntaxModifier(key)]:
            appliedSyntaxToObject(obj[key]),
        }),
        {},
      );
    }

    return obj;
  };

  return appliedSyntaxToObject(object);
};

const isADefinedObject = (object) =>
  object !== null && object !== undefined && object.constructor === Object;

const isFormData = (object) => object?.constructor === FormData;
