import linkifyHtml from 'linkify-html';

export const getUrlParameter = (url) => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};

  let match = regex.exec(url);
  while (match) {
    params[match[1]] = decodeURIComponent(match[2]);
    match = regex.exec(url);
  }

  return params;
};

export const linkifyUrls = (str, option = () => {}) => {
  const regex = /(https?:\/\/[^\s]+)/g;

  const linkified = str.replace(regex, (url) => {
    const options = {
      target: {
        url: '_blank',
      },
      className: 'linkified',
      ...option(url),
    };

    return linkifyHtml(url, options);
  });

  return linkified;
};

export const strContainsUrl = (str) => {
  if (typeof str !== 'string') return false;

  const regex = /https?:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .?-]*)*\/?/g;
  const urls = str.match(regex);

  return (urls && urls.length > 0) || false;
};
