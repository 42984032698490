import emitter from 'hb-react/shared/utils/emitter';

let _isMobileApp = false;

export const isMobileApp = () => _isMobileApp;

export const setIsMobileApp = (value) => {
  _isMobileApp = value;
  emitter.emit('setIsMobileApp', value);
};
