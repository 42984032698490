import { iOS } from 'helpers/mobileDeviceFromUserAgent';

// Preventing iOS Textbox Auto Zooming and ViewPort Sizing
export const preventBouncingViewportIssueOniOS = () => {
  if (document && iOS()) {
    document
      .querySelector('meta[name=viewport]')
      .setAttribute(
        'content',
        'minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1',
      );
  }
};
