import { forwardRef, useContext } from 'react';

import {
  CurrentNetworkContext,
  CurrentSessionContext,
  CurrentUserContext,
  CurrentUrlContext,
  CurrentAdminContext,
} from './DefaultAppContexts';

export const useDefaultAppContexts = () => {
  const { currentNetwork, currentNetworkId, updateCurrentNetwork } = useContext(
    CurrentNetworkContext,
  );

  const { currentSession, updateCurrentSession } = useContext(
    CurrentSessionContext,
  );
  const { currentUser, updateCurrentUser } = useContext(CurrentUserContext);
  const { currentAdmin, updateCurrentAdmin } = useContext(CurrentAdminContext);
  const { currentUrl, updateCurrentUrl } = useContext(CurrentUrlContext);

  if (currentUser && currentAdmin) {
    // eslint-disable-next-line no-console
    console.warn(
      `currentUser and currentAdmin should never be defined at the same time. Use currentUser if you are in the FO, and currentAdmin if you are in the BO.`,
    );
  }

  return {
    currentNetwork,
    currentNetworkId,
    currentSession,
    currentUser,
    currentAdmin,
    currentUrl,
    updateCurrentNetwork,
    updateCurrentSession,
    updateCurrentUser,
    updateCurrentAdmin,
    updateCurrentUrl,
  };
};

const withDefaultAppContexts = (Component) =>
  forwardRef((props, ref) => {
    const contextValue = useDefaultAppContexts();

    return <Component {...contextValue} {...props} ref={ref} />;
  });

export default withDefaultAppContexts;
