import UAParser from 'ua-parser-js';

const UA = new UAParser();
const device = UA.getDevice();

export const Android = () => /Android/i.test(navigator.userAgent);

export const BlackBerry = () => /BlackBerry/i.test(navigator.userAgent);

export const iOS = () =>
  /iP(hone|od|ad)/i.test(navigator.userAgent) ||
  // to detect iPadOS > 13:
  (/Mac/.test(navigator.platform) && 'ontouchstart' in document);

export const Opera = () => /Opera Mini/i.test(navigator.userAgent);

export const Windows = () => /IEMobile|WPDesktop/i.test(navigator.userAgent);

export const any = () =>
  Boolean(
    Android() ||
      BlackBerry() ||
      iOS() ||
      Opera() ||
      Windows() ||
      ['mobile', 'tablet'].includes(device.type),
  );
