import { lazy } from 'react';

export const TopicsAppStandalone = {
  component: lazy(
    () => import('hb-react/shared/apps/Topics/startup/TopicsAppStandalone'),
  ),
};

export const LanguagePicker = {
  component: lazy(
    () => import('hb-react/publicoffice/components/LanguagePicker'),
  ),
};

export const TestFeatureFlagComponent = {
  component: lazy(
    () => import('hb-react/shared/components/TestFeatureFlagComponent'),
  ),
};

export const DonationsPreviewContainer = {
  component: lazy(
    () =>
      import('hb-react/backoffice/apps/Donations/DonationsPreviewContainer'),
  ),
};

export const UnsubscriptionsAppStandalone = {
  component: lazy(
    () =>
      import(
        'hb-react/shared/apps/Unsubscriptions/startup/UnsubscriptionsAppStandalone'
      ),
  ),
};

// Cookie Banner : do NOT rename it, because of some blockers (Brave for example)
export const BannerAppStandalone = {
  component: lazy(
    () =>
      import(
        'hb-react/shared/apps/CookieBanner/startup/CookieBannerAppStandalone'
      ),
  ),
};

export const ToastsApp = {
  component: lazy(() => import('hb-react/shared/apps/Toasts/ToastsApp')),
};

export const UpdateCampaigns = {
  component: lazy(() => import('hb-react/shared/apps/UpdateCampaigns')),
};

export const WithdrawConsentBtn = {
  component: lazy(
    () => import('hb-react/publicoffice/components/Cookies/WithdrawConsentBtn'),
  ),
};

export const MembershipDonations = {
  component: lazy(() => import('hb-react/backoffice/apps/MembershipDonations')),
};

export const MembershipsReportContainer = {
  component: lazy(
    () => import('hb-react/backoffice/apps/MembershipsV2/containers/Report'),
  ),
};

export const PaymentListApp = {
  component: lazy(() => import('hb-react/backoffice/apps/PaymentListApp')),
};

export const AdminNotice = {
  component: lazy(() => import('hb-react/shared/components/AdminNotice')),
};

export const HtmlContent = {
  component: lazy(() => import('hb-react/shared/components/HtmlContent')),
};

export const ReCaptchaField = {
  component: lazy(() => import('hb-react/shared/utils/recaptcha')),
};

export const JobFormBo = {
  component: lazy(() => import('hb-react/shared/apps/JobForm/startup/bo')),
};

export const JobFormPo = {
  component: lazy(() => import('hb-react/shared/apps/JobForm/startup/po')),
};

export const EmailValidationMessage = {
  theme: 'fo',
  component: lazy(
    () => import('hb-react/shared/components/EmailValidationMessage'),
  ),
};

/**
 * @deprecated - only used on old HAML Public Homepage
 */
export const EventsCarousel = {
  theme: 'fo',
  component: lazy(
    () =>
      import('hb-react/frontoffice/components/network_events/EventsCarousel'),
  ),
};

/**
 * @deprecated - only used on old HAML Public Homepage
 */
export const NewsCarousel = {
  theme: 'fo',
  component: lazy(
    () => import('hb-react/frontoffice/apps/News/components/NewsCarousel'),
  ),
};

export const TestimoniesCarousel = {
  theme: 'fo',
  component: lazy(
    () =>
      import('hb-react/publicoffice/standaloneComponents/TestimoniesCarousel'),
  ),
};

export const POMobileAdminCarousel = {
  theme: 'fo',
  component: lazy(
    () =>
      import(
        'hb-react/publicoffice/standaloneComponents/POMobileAdminCarousel'
      ),
  ),
};

export const POMobileCustomCarousel = {
  theme: 'fo',
  component: lazy(
    () =>
      import(
        'hb-react/publicoffice/standaloneComponents/POMobileCustomCarousel'
      ),
  ),
};

export const ProjectForm = {
  component: lazy(() => import('hb-react/shared/apps/ProjectForm')),
  theme: 'fo',
};

export const OutdatedBrowser = {
  component: lazy(() => import('hb-react/shared/apps/OutdatedBrowser')),
};

export const SuperAdminAccessForm = {
  component: lazy(
    () => import('hb-react/backoffice/apps/SuperAdminNetworkAccessForm'),
  ),
};
