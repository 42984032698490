$(document).ready(function(){

  $(document).on('change', '.user_educations_current input[type=checkbox]', function(e){
    var current_education = e.target;
    var $education_fields = $(current_education).parents('.js-education-fields')

    var $to_wrapper = $education_fields.find('.user_educations_to');

    if (current_education.checked){
      $to_wrapper.hide({duration : 100});
    }
    else{
      $to_wrapper.show({duration : 100});
    }
  });

});
