/* LEGACY LIST, DON'T ADD ANY NEW FLAGS IN THIS LIST */

import { getFlag } from 'hb-react/shared/utils/launchDarkly';

export default ({
  flagBraintree3DSecureSca,
  flagBulkAdminInvitations,
  flagCdUniversalTags,
  flagDuplicateAdmin,
  flagMembershipsAddOn,
  flagMembershipsDonation,
  flagProspectPermissions,
  flagStanfordMessagingRequirements,
  testSomeFeatureFlag,
}) => ({
  flags: [
    'flagBraintree3DSecureSca',
    'flagBulkAdminInvitations',
    'flagCdUniversalTags',
    'flagCompaniesFacelift',
    'flagDuplicateAdmin',
    'flagGroupsHeaderFacelift',
    'flagMembershipsAddOn',
    'flagMembershipsDonation',
    'flagProspectPermissions',

    'flagStanfordMessagingRequirements',
    'testSomeFeatureFlag',
  ],
  values: {
    topics: {
      prospectPermissionsEnabled: getFlag({ flagProspectPermissions }),
    },
    payments: {
      braintreeSca: getFlag({ flagBraintree3DSecureSca }),
    },
    messaging: {
      stanfordRequirements: getFlag({ flagStanfordMessagingRequirements }),
    },
    contentDiscovery: {
      universalTags: getFlag({ flagCdUniversalTags }),
    },
    memberships: {
      donation: getFlag({ flagMembershipsDonation }),
      addOn: getFlag({ flagMembershipsAddOn }),
    },
    testSomeFeatureFlag: getFlag({ testSomeFeatureFlag }),
    admins: {
      adminInvitationsBo: getFlag({ flagBulkAdminInvitations }),
      duplicateAdminBo: getFlag({ flagDuplicateAdmin }),
    },
  },
});
