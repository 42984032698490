import { useTheme } from 'styled-components';

import { FONT_BRIGHTNESS } from '@hivebrite/design-system/Theme';

// Input & dropdown can be customize by the Admin in the BO
const useInputAndDropdownTheme = () => {
  const theme = useTheme();

  if (theme?.themeName === 'bo') {
    return {
      ...theme?.input,
      themeName: 'bo',
      // Resetting default value
      bg: '#FFFFFF',
      borderColor: theme?.colors?.black,
      color: theme?.colors?.asphalt,
      iconColor: theme?.colors?.black,
      placeholder: theme?.colors?.dove,
      fontBrightness: FONT_BRIGHTNESS.DARK,
    };
  }

  return {
    ...theme?.input,
    fontBrightness: theme?.themeVariables?.inputFontBrightness,
    themeName: 'fo',
  };
};

export default useInputAndDropdownTheme;
