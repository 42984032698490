export function convertInstagramUsernameToURL(instagram_profile_url) {
  let instagram_url_regex = /http(s)?:\/\/(www\.)?(instagram)\.com\/[A-z0-9_\-.]+\/?/;
  let instagram_default_url = 'https://www.instagram.com/';
  let profile = null;

  if (!instagram_profile_url.match(instagram_url_regex))
    profile = `${instagram_default_url}${instagram_profile_url}`;

  return profile;
}
