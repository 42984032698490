export const executeExternalScript = (content) => {
  const contentHtml = document.createElement('div');

  contentHtml.innerHTML = content;
  const scripts = contentHtml.getElementsByTagName('script');

  for (let n = 0; n < scripts.length; n++) {
    if (scripts[n]) {
      // eslint-disable-next-line no-eval
      eval(scripts[n].innerHTML);
    }
  }
};
