/*
 *
 * THIS IS A LEGACY FILE, DON'T ADD ANYTHING NEW IN IT
 *
 * For new components, use instead app/assets/javascripts/initializers/react/components
 * and use react_component helper in .haml file
 *
 */

import { get } from 'lodash';
import { createElement, lazy } from 'react';

import ReactRenderer from 'hb-react/shared/helpers/ReactRenderer';
import lazyLoadGlobalComponents from 'hb-react/shared/utils/lazyLoadGlobalComponents';
import ThemeProvider from 'hb-react/style/ThemeProvider';

window.ReactRenderer = ReactRenderer;

const components = {
  CompanyQuickForm: lazy(
    () => import('hb-react/frontoffice/components/CompanyQuickForm/Standalone'),
  ),
  InputLocation: lazy(
    () => import('hb-react/frontoffice/components/inputs/InputLocation'),
  ),
  MapboxMap: lazy(() => import('hb-react/frontoffice/components/MapboxMap')),
  EventsAppStandalone: lazy(
    () => import('hb-react/shared/apps/Events/startup/EventsAppStandalone'),
  ),
  // TODO: migrate to react_renderer
  PageCustomizableStandalone: lazy(
    () =>
      import(
        'hb-react/shared/apps/PageCustomizables/startup/PageCustomizableStandalone'
      ),
  ),
  InputSearch: lazy(
    () => import('hb-react/shared/components/inputs/InputSearch'),
  ),
  DateTimePicker: lazy(
    () => import('hb-react/shared/components/DateTimePicker'),
  ),
  Modal: lazy(() => import('hb-react/publicoffice/components/Modal')),
  ReactSelect: lazy(
    () => import('hb-react/publicoffice/components/ReactSelect'),
  ),
  ThemeProvider: (props) =>
    createElement(ThemeProvider, {
      theme: 'po',
      options: {
        customNetworkColors: get(
          window,
          'AlumniConnect.currentNetwork.colors',
          {},
        ),
      },
      ...props,
    }),
};

lazyLoadGlobalComponents(components);
