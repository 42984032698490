$(function () {
  $(document).on('click', function (e) {
    const $link = $(e.target).closest('a[href]');
    const href = $link.attr('href');

    // @TODO Quick & Dirty fix to prevent the click event override in case of add event click.
    if ($(e.target)?.parents()?.find('.react-add-to-calendar')?.length > 0) {
      return true;
    }

    // This file is clearly a hack
    // It has to be removed as soon as cordova and mobile has been inspected for potential side effect
    if (
      $link.attr('target') === '_blank' &&
      href.startsWith('http') &&
      $link.data('open-type') === 'new_tab'
    ) {
      window.open(href, '_blank');
      e.preventDefault();

      return;
    }

    // A Link component should implement this kind of logic. It shouldn't be attached to the whole dom.
    // That override other logics and trigger side effects difficult to find.
    if (
      $link.attr('target') === '_blank' &&
      href.startsWith('http') &&
      $link.data('open-type') !== 'silent'
    ) {
      // `_system` has no special behavior like `_blank`, `_parent`	,`_self` or `_top`
      // this imply that the next link will be opened in the same tab than the previous one
      // this can be potentially fixed by randomize the frame/tab/window name targeted
      window.open(href, '_system');
      e.preventDefault();

      return;
    }
  });
});
