import markerImg from 'hb-react/frontoffice/components/LeafletMap/marker.png';

export const mapImage = ({
  zoom = 11,
  height = 400,
  width = 800,
  lng,
  lat,
  offsetX = 0,
  offsetY = 0,
}) => {
  const markerUrl = encodeURIComponent(markerImg);
  let urlParams = '';

  // MapBox can't use local url for add marker, so only add the marker if we are on PROD env
  if (__PROD__) {
    urlParams += `url-${markerUrl}(${lng},${lat})/`; // marker url
  }

  urlParams += `${lng + offsetX},${lat + offsetY},${zoom}`; // lng, lat, zoom
  urlParams += `/${width}x${height}`; // size
  urlParams += `?access_token=${window.__HB_ENV__.MAPBOX_KEY}`; // access token

  return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${urlParams}`;
};

export { markerImg };
