/* eslint-disable */
$(document).ready(() => {
  $(document).on(
    'change',
    '.user_experiences_current input[type=checkbox]',
    (e) => {
      const current_experience = e.target;
      const $experience_fields = $(current_experience).parents(
        '.js-experience-fields',
      );

      const $to_wrapper = $experience_fields.find('.user_experiences_to');

      const experience = {};

      experience.id = $experience_fields.data('id');
      experience.position = $experience_fields
        .find('.user_experiences_position input')
        .val();
      experience.company_name = $experience_fields
        .find('.user_experiences_company input')
        .val();

      if (current_experience.checked) {
        $to_wrapper.hide({ duration: 100 });
      } else {
        $to_wrapper.show({ duration: 100 });
      }
    },
  );

  $(document).on(
    'change',
    '.user_experiences_from_unknown input[type=checkbox]',
    (e) => {
      const from_unknown = e.target;
      const $from_unknown_wrapper = $(from_unknown).parents(
        '.user_experiences_from_unknown',
      );
      const $experience_fields = $(from_unknown).parents(
        '.js-experience-fields',
      );

      const $from_wrapper = $experience_fields.find('.user_experiences_from');

      if (from_unknown.checked) {
        $from_wrapper.hide({ duration: 100 });
      } else {
        $from_wrapper.show({ duration: 100 });
        $from_unknown_wrapper.hide();
      }
    },
  );
});
