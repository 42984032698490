import { isEmpty } from 'lodash';

export const completeName = (user = {}) =>
  [
    [user.firstname, user.prefix_name, user.lastname].filter(Boolean).join(' '),
    user.suffix_name,
  ]
    .filter(Boolean)
    .join(', ');

export const fullName = (user = {}, guest = {}) => {
  if (isEmpty(user)) {
    return `${guest.firstname} ${guest.lastname}`;
  }

  const honoraryTitle =
    user.honorary_title && I18n.t(`honorary_title.${user.honorary_title}`);

  const beforeComma = [
    honoraryTitle,
    user.prefix_firstname,
    user.firstname,
    user.prefix_name,
    user.lastname,
  ]
    .filter(Boolean)
    .join(' ');

  const afterComma = [
    user.suffix_name,
    user.maidenname && `(${user.maidenname})`,
  ]
    .filter(Boolean)
    .join(' ');

  const commaNeeded = !!user.suffix_name;

  return [beforeComma, afterComma]
    .filter(Boolean)
    .join(commaNeeded ? ', ' : ' ');
};
