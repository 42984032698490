import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { Box } from '@hivebrite/design-system/Box';

import getLocaleDistanceUnit from 'locales/getLocaleDistanceUnit';

const convertValue = (value, localeDistance) => {
  if (localeDistance.unit !== 'km') {
    // Always sending kilometers in the request
    // We're converting Miles to Kilometers here
    return value / localeDistance.conversion.from_km;
  }

  return value;
};

const InputRadius = ({
  originalValue,
  inputName,
  canUpdate,
  onUpdateRange,
}) => {
  const localeDistance = getLocaleDistanceUnit(I18n.locale);
  let convertedValue;

  if (originalValue !== 0 && localeDistance.unit === 'mi') {
    // original values are always in km, but when the unit is mi
    // we need to convert it back to mi, otherwise we will display the original value which is always in km
    convertedValue = Math.round(
      originalValue * localeDistance.conversion.from_km,
    );
  } else {
    convertedValue = originalValue;
  }

  // this sets the max to either 64 km or 50 mi
  const max = localeDistance.unit === 'km' ? 64 : 50;

  const fakeId = useMemo(() => uuid(), []);
  const [value, setValue] = useState(originalValue);
  const [displayValue, setDisplayValue] = useState(convertedValue);

  useEffect(() => {
    if (!canUpdate) {
      setValue(0);
      setDisplayValue(0);
    }
  }, [canUpdate]);

  const updateRange = useCallback(
    debounce(() => {
      if (canUpdate && onUpdateRange) {
        onUpdateRange();
      }
    }, 1000),
    [canUpdate],
  );

  const handleInputChange = useCallback(
    (event) => {
      const inputValue = event.target.value;
      const calculatedValue = convertValue(inputValue, localeDistance);

      setValue(calculatedValue);
      setDisplayValue(inputValue);

      updateRange();
    },
    [localeDistance, updateRange],
  );

  return (
    <Box display="block" ml={4} mb={2} mt={1} opacity={canUpdate ? 1 : 0.4}>
      <input type="hidden" name={inputName} value={value} />
      <label htmlFor={fakeId}>
        {s__('InputRadius|Radius (in %{unitName})').i__({
          unitName: localeDistance.unit,
        })}
      </label>
      <input
        className="uk-input"
        disabled={!canUpdate}
        id={fakeId}
        name={fakeId}
        onChange={handleInputChange}
        value={displayValue}
        type="number"
        min={0}
        max={max}
      />
    </Box>
  );
};

export default InputRadius;

InputRadius.propTypes = {
  inputName: PropTypes.string.isRequired,
  canUpdate: PropTypes.bool,
  onUpdateRange: PropTypes.func,
};

InputRadius.defaultProps = {
  canUpdate: true,
};
