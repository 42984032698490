import { assign, extend, map, flatten, uniq, forEach } from 'lodash';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import AutocompleteTemplate from 'hb-react/backoffice/apps/Companies/components/TypeheadForm/AutocompleteTemplate';
import CreateTemplate from 'hb-react/backoffice/apps/Companies/components/TypeheadForm/CreateTemplate';
import UserAutocompleteTemplate from 'hb-react/backoffice/apps/Companies/components/TypeheadForm/UserAutocompleteTemplate';

// Bloodhound
import('corejs-typeahead');

/* eslint-disable */

window.datepicker_formatDate = function (iso_date) {
  // en fallback if unknow locale
  const datepickerLocale =
    $.datepicker.regional[I18n.locale] || $.datepicker.regional['en'];
  const format = datepickerLocale.dateFormat;

  if (typeof iso_date == 'string') iso_date = new Date(iso_date);

  return $.datepicker.formatDate(format, iso_date);
};

window.extend_with_csrf_params = function (object) {
  const key = $('meta[name=csrf-param]').attr('content');
  object[key] = $('meta[name=csrf-token]').attr('content');

  return object;
};

window.dynamic_input_splitter_into_array = function (userInput) {
  const $userInput = $(userInput);
  $userInput.attr('name', $userInput.attr('name') + ':listField');
};

window.listFieldSerializer = {
  customTypes: {
    listField: function (str) {
      return str
        .split(/,|\n|;/)
        .filter((str) => str)
        .map((str) => str.trim());
    },
  },
};

/**
 * [remote_select2_search description]
 * @param  {Object} input   jQuery DOM Object
 * @param  {Object} options select2 options
 * @return {Object}         Dom Object
 */
window.remote_select2_search = function (input, options) {
  if (typeof options === 'undefined') {
    options = {};
  }

  const $dialog = input.closest('dialog');

  return input.select2(
    assign($dialog.length ? { dropdownParent: $dialog } : {}, {
      width: options.width,
      multiple: options.multiple,
      templateResult: options.templateResult,
      placeholder: options.placeholder,
      minimumInputLength: options.minimumInputLength ?? 1,
      ajax: {
        url: options.url,
        delay: 250,
        dataType: 'JSON',
        data: function (term) {
          return extend(term, {
            network_id: options.network_id,
            topic_id: options.topic_id,
            ...options.query,
          });
        },
        processResults: function (data) {
          const results = { more: false, results: [] };

          $.each(data, function (i, val) {
            results.results.push({
              id: val.id,
              text: val.name,
              name: val.name,
              logo_url: val.logo_url,
              short_description: val.short_description,
              inline_location: val.inline_location,
            });
          });
          results.results = results.results.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
          return results;
        },
      },
    }),
  );
};

window.select2_companies = function (input_companies, options) {
  options.url = Routes.search_companies_path;

  options.templateResult = (company) =>
    $(
      ReactDOMServer.renderToStaticMarkup(
        <AutocompleteTemplate {...company} />,
      ),
    );

  return remote_select2_search(input_companies, options);
};

window.select2_schools = function (input_schools, options) {
  options.url = Routes.search_schools_path;

  return remote_select2_search(input_schools, options);
};

window.select2_degrees = function (input_degrees, options) {
  options.url = Routes.search_degrees_path;

  return remote_select2_search(input_degrees, options);
};

window.select2_field_of_studies = function (input_field_of_studies, options) {
  options.url = Routes.search_field_of_studies_path;

  return remote_select2_search(input_field_of_studies, options);
};

window.select2_industries = function (input_industries, options) {
  if (typeof options == 'undefined') options = {};

  if (
    AlumniConnect &&
    AlumniConnect.other_infos &&
    AlumniConnect.other_infos.industries
  ) {
    const sortedIndustries = AlumniConnect.other_infos.industries.sort(
      function (a, b) {
        return a.name.localeCompare(b.name);
      },
    );

    const $dialog = input_industries.closest('dialog');

    return input_industries.select2(
      assign($dialog.length ? { dropdownParent: $dialog } : {}, {
        width: options.width,
        multiple: options.multiple,
        templateResult: options.templateResult,
        data: map(sortedIndustries, function (industry) {
          return {
            id: industry.id,
            text: industry.name,
          };
        }),
      }),
    );
  } else {
    options.url = '/search/industries';

    return remote_select2_search(input_industries, options);
  }
};

window.select2_skills = function (input_skills, options) {
  options.url = '/search/skills';

  return remote_select2_search(input_skills, options);
};

// ----------- User Autocomplete --------------------

window.autocomplete_user_typehead = function ($input, options) {
  let engine, url;
  // To remove
  let params = extend(
    { network_id: options.network_id, format: 'js' },
    options.query,
  );
  options.source = Routes.quick_search_backoffice_networks_path(params);
  engine = new Bloodhound({
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: unescape(
        Routes.quick_search_backoffice_networks_path(
          extend(
            {
              term: '%QUERY',
              format: 'js',
              network_id: options.network_id,
            },
            options.query,
          ),
        ),
      ),
      wildcard: '%QUERY',
    },
  });

  engine.initialize();

  $input.typeahead(
    {
      highlight: true,
      minLength: 1,
      autoselect: true,
      classNames: {
        menu: 'dropdown-menu hard user-search-dropdown',
        cursor: 'search-view--selected',
      },
    },
    {
      display: 'name',
      templates: {
        suggestion: (user) =>
          ReactDOMServer.renderToStaticMarkup(
            <UserAutocompleteTemplate {...user} />,
          ),
      },
      source: engine.ttAdapter(),
    },
  );

  $input.on('typeahead:selected', function (event, user) {
    return $($(this).data('el')).val(user.id);
  });
};

// ----------- Juridiction fields visibility --------------------

window.juridiction_fields_visibility = function (juridiction) {
  const $juridiction = $(juridiction);

  const showJuridictionSpecificFields = function () {
    $('.js-juridiction-specific-field').hide();
    $('.js-juridiction-specific-field-' + $juridiction.val()).show();
  };

  showJuridictionSpecificFields();

  $juridiction.change(function () {
    showJuridictionSpecificFields();
  });
};

// ----------- Companies Autocomplete --------------------

window.autocomplete_companies = (container, options = {}) => {
  const $container = $(container);

  if ($container.length === 0) {
    console.warn('autocomplete_companies: no container element provided');
  }

  $container.each((index, element) => {
    const $element = $(element);
    let selectedValue = null;
    let engine;
    let $input;
    let $elementIdValue;

    // INIT

    if (options.input) {
      $input = $(options.input);
    } else {
      $input = $element.find('[data-behavior=search-company-input]');
    }

    if ($input.length === 0) {
      console.warn(
        "autocomplete_companies: no input option provied OR no input with attribute '[data-behavior=search-company-input]' found in provided '#{container}' container element",
      );

      return;
    }

    if (!options.selectedCallback) {
      $elementIdValue = $element.find($input.data('el'));

      if ($elementIdValue.length === 0) {
        console.warn(
          "autocomplete_companies: No selectedCallback option provied OR can't find '#{$(@).data('el')}' company id input element",
        );

        return;
      }
    }

    // INTERN CALLBACKS

    const selectedCallback = (event, company) => {
      selectedValue = company;

      if (options.selectedCallback) {
        options.selectedCallback(event, company);
      } else {
        $elementIdValue.val(company.id);
      }
    };

    const closeCallback = (event, value) => {
      // Clear remote cache for when a company is created
      engine.clearRemoteCache();
    };

    const blurCallback = (event) => {
      if (selectedValue) {
        $input.val(selectedValue.name);
      } else {
        $input.val('');
      }
    };

    // BOOTSTRAP TYPEHEAD

    engine = new Bloodhound({
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: unescape(
          Routes.search_companies_path({
            term: '%QUERY',
            network_id: options.network_id,
            format: 'js',
            from_admin: true,
          }),
        ),
        wildcard: '%QUERY',
      },
    });

    engine.initialize();

    $input.typeahead(
      {
        highlight: true,
        minLength: 1,
        autoselect: true,
        classNames: {
          menu: 'dropdown-menu hard company-search-dropdown one-whole',
          cursor: 'search-view--selected',
        },
      },
      {
        display: 'name',
        limit: 200,
        templates: {
          suggestion: (company) =>
            ReactDOMServer.renderToStaticMarkup(
              <AutocompleteTemplate {...company} />,
            ),
          footer:
            options.enableCreateCompany &&
            (() =>
              ReactDOMServer.renderToStaticMarkup(
                <CreateTemplate
                  enableCreateCompany={options.enableCreateCompany}
                />,
              )),
          notFound:
            options.enableCreateCompany &&
            (() =>
              ReactDOMServer.renderToStaticMarkup(
                <CreateTemplate
                  enableCreateCompany={options.enableCreateCompany}
                />,
              )),
        },
        source: engine.ttAdapter(),
      },
    );

    // LISTENERS

    $input.on('typeahead:selected', selectedCallback);
    $input.on('typeahead:close', closeCallback);
    $input.on('blur', blurCallback);

    if (options.createCompanyCallback) {
      $container.on(
        'click',
        '.js-create-company',
        options.createCompanyCallback,
      );
    }
  });
};

// ----------- Dynamic Attributes Double Select --------------------

window.dynamic_attributes_double_select = (elem, opts = {}) => {
  opts = { display_label: true, ...opts };

  const init_double_select = function () {
    const construct_full_name_attr = (child = false) => {
      if (child) {
        return `${double_select_name_attr}[child_value][]`;
      } else {
        return opts.search_mode
          ? `${double_select_name_attr}[root_value][]`
          : `${double_select_name_attr}[root_value]`;
      }
    };

    const select_template = (options = [], html_opts = {}) => {
      const multiple = html_opts.multiple ? 'multiple' : '';
      const $wrapper = $(`
                <div class='input flush--bottom ${html_opts.wrapper_html && html_opts.wrapper_html.class}'>
                    <select name='${html_opts.name}' select2title='${html_opts.select2title || ''}' ${multiple}></select>
                </div>
            `);

      options.unshift('');
      options.forEach((option) => {
        const $option = $('<option />', {
          value: option,
          text: option,
        });
        $wrapper.find('select').append($option);
      });

      if (html_opts.label && opts.display_label) {
        $wrapper.prepend(
          `<label class='label--block'>${html_opts.label}</label>`,
        );
      }

      if (html_opts.placeholder && opts.display_placeholder) {
        $wrapper.find('select').attr('data-placeholder', html_opts.placeholder);
      }

      return $wrapper;
    };

    const insert_double_select_inputs = () => {
      let $child_wrapper = null;
      let $child_select = null;

      let options = Object.keys(double_select_options);
      let html_opts = {
        name: construct_full_name_attr(),
        label: double_select_label,
        select2title: double_select_title,
        placeholder: double_select_placeholder,
        wrapper_html: opts.parent_wrapper_html,
        multiple: opts.search_mode,
      };
      const $select = select_template(options, html_opts)
        .appendTo($container)
        .find('select');
      let $dialog = $select.closest('dialog');

      $select
        .select2({
          dropdownParent: $dialog.length ? $dialog : undefined,
          placeholder: double_select_placeholder || I18n.t('select_an_option'),
          allowClear: true,
          disabled: $elem.attr('disabled'),
        })
        .change(opts.onChange)
        .change((_) => {
          if ($child_wrapper) {
            $child_select.select2('destroy');
            $child_wrapper.remove();
          }

          const rootValues = Array.isArray($select.val())
            ? $select.val()
            : [$select.val()];

          let options = rootValues.map((value) => double_select_options[value]);

          options = flatten(options);
          options = uniq(options);

          html_opts = {
            name: construct_full_name_attr(true),
            label: doubleSelectConfig.child_label || $select.val(),
            placeholder: $select.val(),
            wrapper_html: opts.child_wrapper_html,
            multiple: opts.search_mode,
          };

          $child_wrapper = select_template(options, html_opts).appendTo(
            $container,
          );

          $dialog = $child_wrapper.find('select').closest('dialog');
          $child_select = $child_wrapper
            .find('select')
            .select2({
              dropdownParent: $dialog.length ? $dialog : undefined,
              placeholder: I18n.t('select_an_option'),
              allowClear: true,
              disabled: $elem.attr('disabled'),
            })
            .change(opts.onChange);

          if (data) {
            $child_select.val(data.child_value).trigger('change');
          }
        })
        .on('select2:unselecting', (e) => {
          e.preventDefault();
          $select.val('').trigger('change');

          if ($child_wrapper) {
            $child_wrapper.remove();
            $child_wrapper = null;
          }
        });

      if (data) {
        $select.val(data.root_value).trigger('change');
        $child_select.val(data.child_value).trigger('change');
      }

      $select.next('.select2-container').tooltip({
        title: () => $(this).prev().attr('select2title'),
        placement: 'auto',
      });
    };

    const load_data = () => {
      try {
        return JSON.parse($elem.val());
      } catch (error) {
        return null;
      }
    };

    // MAIN
    const $elem = $(this);

    const doubleSelectConfig = $elem.data('double-select-options');

    if (!doubleSelectConfig) {
      throw '[data-double-select-options] attribute not provided';
    }

    if ($elem.data('double_select')) {
      return;
    } else {
      $elem.data('double_select', true);
    }

    const $wrapper = $elem.parent();

    $wrapper.removeClass('input');

    const $container = $(
      `<div class='${opts.wrapper_html && opts.wrapper_html.class}'/>`,
    ).prependTo($wrapper);
    const double_select_name_attr = $elem.attr('name');
    const double_select_label = $elem.siblings('label').hide().text();
    const double_select_placeholder = $elem.attr('placeholder');
    const double_select_title = $elem.attr('select2title');

    $elem.hide();
    $elem.attr('name', '');
    const double_select_options = doubleSelectConfig.options;
    const data = load_data();

    insert_double_select_inputs();
  };

  $(elem).each(function () {
    init_double_select.call(this);
  });

  $(document).on('focus', elem, init_double_select);
};

// ----------- Dynamic Attributes Select --------------------

window.dynamic_attributes_select = (elem, opts = {}) => {
  const load_config = ($elem) => {
    try {
      return $elem.data('select-config');
    } catch (error) {
      return {};
    }
  };

  const init_select = function () {
    const $elem = $(this);

    if (!$elem.data('select-config')) {
      throw '[data-select-config] attribute not provided';
    }

    const config = load_config($elem);

    if (!config.remote) {
      $elem.select2(opts).change(opts.onChange);
    } else {
      const network_id = opts.network_id || config.network_id;
      const customizable_attribute_id = config.customizable_attribute_id;

      const $dialog = $elem.closest('dialog');

      $elem
        .select2({
          dropdownParent: $dialog.length ? $dialog : undefined,
          width: opts.width,
          minimumInputLength: 1,
          ajax: {
            url: Routes.search_customizable_attribute_select_path(),
            delay: 250,
            dataType: 'JSON',
            data: (term) =>
              extend(term, {
                network_id: network_id,
                customizable_attribute_id: customizable_attribute_id,
              }),
            processResults: (data) => {
              let results = { more: false, results: [] };

              forEach(data, (i, val) => {
                results.results.push({
                  id: val,
                  text: val,
                });
              });

              return results;
            },
          },
        })
        .change(opts.onChange);
    }

    $elem.next('.select2-container').tooltip({
      title: function () {
        return $(this).prev().attr('select2title');
      },
      placement: 'auto',
    });
  };

  $(elem).each(function () {
    init_select.call(this);
  });
};

// ----------- Dynamic Attributes Double React Select --------------------
// Adjusted copy of `dynamic_attributes_double_select` with implementation of react_select instead of select2.

window.dynamic_attributes_double_react_select = (elem, opts = {}) => {
  opts = { ...opts, display_label: true };

  const init_double_select = function () {
    const construct_full_name_attr = (child = false) => {
      if (child) {
        return `${double_select_name_attr}[child_value][]`;
      } else {
        return opts.search_mode
          ? `${double_select_name_attr}[root_value][]`
          : `${double_select_name_attr}[root_value]`;
      }
    };

    const select_template = (html_opts = {}) => {
      const multiple = html_opts.multiple ? 'multiple' : '';

      const $wrapper = $(`
        <div class='input ${(html_opts.wrapper_html && html_opts.wrapper_html.class) || ''}'>
          <div class='react-select-wrap'></div>
        </div>
      `);

      if (html_opts.label && opts.display_label) {
        $wrapper.prepend(
          `<label class='label--block'>${html_opts.label}</label>`,
        );
      }

      return $wrapper;
    };

    const insert_double_select_inputs = () => {
      let $child_wrapper = null;
      let $child_select = null;

      const on_child_change = (selected) => {
        if (typeof opts.onChange === 'function') {
          opts.onChange();
        }
      };

      const on_root_change = (selected, child_value) => {
        if ($child_wrapper) {
          ReactDOM.unmountComponentAtNode($child_wrapper[0]);
          $child_wrapper.remove();
        }

        if (!selected) {
          return;
        }

        const rootValues = Array.isArray(selected)
          ? selected.map((s) => (typeof s === 'object' ? s?.value : s))
          : [typeof selected === 'object' ? selected?.value : selected];

        let options = rootValues.map((value) => double_select_options[value]);

        options = flatten(options);
        options = uniq(options);

        const html_opts = {
          name: construct_full_name_attr(true),
          label: doubleSelectConfig.child_label || rootValues[0],
          placeholder: rootValues[0],
          wrapper_html: opts.child_wrapper_html,
          multiple: opts.search_mode,
        };

        $child_wrapper = select_template(html_opts).appendTo($container);

        const defaultValue =
          Array.isArray(child_value) && html_opts.multiple
            ? child_value
            : Array.isArray(child_value)
              ? child_value[0]
              : child_value;

        const ReactSelectChildComponents = React.createElement(ReactSelect, {
          name: html_opts.name,
          ariaLabel: html_opts.label,
          options: options,
          defaultValue: defaultValue,
          disabled: $elem.attr('disabled'),
          placeholder: I18n.t('select_an_option'),
          isMulti: html_opts.multiple,
          isClearable: true,
          onChange: on_child_change,
        });
        ReactDOM.render(
          ReactSelectChildComponents,
          $child_wrapper.find('.react-select-wrap')[0],
        );
      };

      const options = Object.keys(double_select_options);

      const html_opts = {
        name: construct_full_name_attr(),
        label: double_select_label,
        select2title: double_select_title,
        placeholder: double_select_placeholder,
        wrapper_html: opts.parent_wrapper_html,
        multiple: opts.search_mode,
      };

      const $react_container = select_template(html_opts)
        .appendTo($container)
        .find('.react-select-wrap');

      const ReactSelectComponents = React.createElement(ReactSelect, {
        name: html_opts.name,
        ariaLabel: double_select_label,
        options: options,
        defaultValue: data && data.root_value,
        disabled: $elem.attr('disabled'),
        placeholder: double_select_placeholder || I18n.t('select_an_option'),
        isMulti: html_opts.multiple,
        isClearable: true,
        onChange: on_root_change,
      });

      ReactDOM.render(ReactSelectComponents, $react_container[0]);

      if (data) {
        on_root_change(data.root_value, data.child_value);
        on_child_change();
      }

      $react_container.tooltip({
        title: () => double_select_title,
        placement: 'auto',
      });
    };

    // DATA ######

    const load_data = () => {
      try {
        return JSON.parse($elem.val());
      } catch (error) {
        return null;
      }
    };

    // MAIN ######

    const $elem = $(this);

    const doubleSelectConfig = $elem.data('double-select-options');

    if (!doubleSelectConfig) {
      throw '[data-double-select-options] attribute not provided';
    }

    if ($elem.data('double_select')) {
      return;
    } else {
      $elem.data('double_select', true);
    }

    const $wrapper = $elem.parent();

    $wrapper.removeClass('input');

    const $container = $(
      `<div class='${(opts.wrapper_html && opts.wrapper_html.class) || ''}'/>`,
    ).prependTo($wrapper);

    const double_select_name_attr = $elem.attr('name');
    const double_select_label = $elem.siblings('label').hide().text();
    const double_select_placeholder = $elem.attr('placeholder');
    const double_select_title = $elem.attr('select2title');

    $elem.hide();
    $elem.attr('name', '');

    const double_select_options = doubleSelectConfig.options;
    const data = load_data();

    insert_double_select_inputs();
  };

  $(elem).each(function () {
    init_double_select.call(this);
  });

  $(document).on('focus', elem, init_double_select);
};
