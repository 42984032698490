import { lazy } from 'react';

import { ButtonInLink as DSButtonInLink } from '@hivebrite/design-system/ButtonInLink';

export const SignupPaymentsBreadcrumbs = {
  theme: 'fo',
  component: lazy(
    () =>
      import(
        'hb-react/shared/apps/Memberships/containers/SignupPayments/bridge/SignupPaymentsBreadcrumbs'
      ),
  ),
};

export const JobsAppStandalone = {
  theme: 'po',
  component: lazy(
    () => import('hb-react/publicoffice/apps/Jobs/JobsAppStandalone'),
  ),
};

export const MembershipsV2AppStandalone = {
  component: lazy(
    () =>
      import(
        'hb-react/shared/apps/Memberships/startup/MembershipsV2AppStandalone'
      ),
  ),
};

export const PageCustomizableStandalone = {
  theme: 'po',
  component: lazy(
    () =>
      import(
        'hb-react/shared/apps/PageCustomizables/startup/PageCustomizableStandalone'
      ),
  ),
};

export const SignupPaymentsApp = {
  theme: 'fo',
  component: lazy(
    () => import('hb-react/shared/apps/Memberships/startup/SignupPaymentsApp'),
  ),
};

export const PasswordResetApp = {
  theme: 'po',
  component: lazy(() => import('hb-react/publicoffice/apps/PasswordResetApp')),
};

export const PasswordChangeApp = {
  theme: 'po',
  component: lazy(() => import('hb-react/publicoffice/apps/PasswordChangeApp')),
};

export const NewsAppClientStandalone = {
  theme: 'po',
  component: lazy(
    () =>
      import('hb-react/frontoffice/apps/News/startup/NewsAppClientStandalone'),
  ),
};

export const DonationsAppStandalone = {
  theme: 'po',
  component: lazy(
    () => import('hb-react/publicoffice/apps/Donations/DonationsAppStandalone'),
  ),
};

export const LoginApp = {
  theme: 'po',
  component: lazy(() => import('hb-react/publicoffice/apps/LoginApp')),
};

export const ErrorCard = {
  theme: 'po',
  component: lazy(() => import('hb-react/shared/components/ErrorCard')),
};

export const ButtonInLink = {
  theme: 'po',
  component: DSButtonInLink,
};
