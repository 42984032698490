/* eslint-disable jquery/no-data, jquery/no-ready, no-undef, jquery/no-each */
import { createRoot } from 'react-dom/client';

import InputLocation from 'hb-react/frontoffice/components/inputs/InputLocation';

window.renderLocationPicker = function () {
  $('.js-location-picker').each(function () {
    const $this = $(this);

    if (this.classList.contains('react-component-rendered')) {
      return;
    }

    const picker = React.createElement(
      ThemeProvider,
      {},
      React.createElement(InputLocation, {
        inputName: $this.data('inputName'),
        placeholder: $this.data('placeholder'),
        primaryInputName: $this.data('primaryInputName'),
        defaultLocation: $this.data('defaultLocation'),
        biasLocationCode: $this.data('biasLocationCode'),
        label: $this.data('label'),
        labeledBy: $this.data('labeled-by'),
        id: $this.data('id'),
        radius: {
          enabled: $(this).data('radiusEnabled'),
          inputName: $(this).data('radiusInputName'),
        },
        disabled: $(this).data('disabled'),
      }),
    );

    const root = createRoot(this);

    root.render(picker, () => {
      this.classList.add('react-component-rendered');
    });
  });
};

$(() => {
  window.renderLocationPicker();
});
