/* eslint-disable */

(function ($) {
  $.fn.beautifullCoverSize = function () {
    function getMeta(el) {
      var r = $.Deferred();

      $('<img/>')
        .attr('src', $(el).data('picture-url'))
        .on('load', function () {
          r.resolve({
            w: this.width,
            h: this.height,
          });
        });
      return r;
    }

    this.each(function () {
      getMeta(this).done(
        function (sizes) {
          var ratioDiv = $(this).width() / $(this).height();
          var ratioImage = sizes.w / sizes.h;
          var cover =
            ratioImage > ratioDiv * 0.8 && ratioImage < ratioDiv * 1.2;
          var auto = sizes.w < $(this).width() && sizes.h < $(this).height();

          $(this).css(
            'background-image',
            'url(' + $(this).data('picture-url') + ')',
          );
          if (cover) {
            $(this).css('background-size', 'cover');
          } else {
            $(this).css('background-size', auto ? 'auto' : 'contain');
          }
        }.bind(this),
      );
    });
    return this;
  };
})(jQuery);
