import type { EngineDataLocation } from 'hb-react/frontoffice/components/inputs/InputLocation/types';
import { getDistanceFromLatLonInKm } from 'hb-react/shared/utils/getDistanceFromLatLonInKm';

export const EXACT_LOCATION_DISTANCE_BUFFER = 0.25;

export const getDistanceFromBounds = (
  bounds?: EngineDataLocation['bounds'],
): number | undefined => {
  let distance;

  if (bounds) {
    const boundsRange = getDistanceFromLatLonInKm(
      bounds[0][0],
      bounds[0][1],
      bounds[1][0],
      bounds[1][1],
    );

    distance = Math.round(boundsRange / 2);

    // Exact addresses don't match if distance is 0,
    // add a very small distance to avoid this.
    if (distance === 0) {
      distance = EXACT_LOCATION_DISTANCE_BUFFER;
    }
  }

  return distance;
};
