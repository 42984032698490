const SESSION_STORAGE_GLOBAL_KEY = '@hivebrite';
const SESSION_STORAGE_DEFAULT_KEY = 'hivebrite';

export const setItem = ({ key = SESSION_STORAGE_DEFAULT_KEY, value }) => {
  const dataSession =
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_GLOBAL_KEY)) || {};

  dataSession[key] = value;

  sessionStorage.setItem(
    SESSION_STORAGE_GLOBAL_KEY,
    JSON.stringify(dataSession),
  );
};

export const getItem = ({ key = SESSION_STORAGE_DEFAULT_KEY }) => {
  try {
    const hivebriteSessionData = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_GLOBAL_KEY),
    );

    return hivebriteSessionData[key];
  } catch {
    return undefined;
  }
};

// Store multiple values into an Array.
// This util manages the current and new value types to set an Array value.
export const addToStorage = ({
  key = SESSION_STORAGE_DEFAULT_KEY,
  value,
  allowDuplicate = true,
  limit,
}) => {
  const currentValue = getItem({ key }) || [];

  // If current value is a string, transform it into an Array to store new values
  const castCurrentValue = currentValue
    ? typeof currentValue === 'string'
      ? [currentValue]
      : currentValue
    : [];

  const castValueToAdd = allowDuplicate
    ? value
    : castCurrentValue.indexOf(value) === -1
    ? value
    : undefined;

  const newFullValue = [...castCurrentValue, castValueToAdd].filter(Boolean);

  const newValue =
    limit && newFullValue.length > limit
      ? newFullValue.splice(newFullValue.length - limit, newFullValue.length)
      : newFullValue;

  setItem({ key, value: newValue });
};
