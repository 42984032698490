// Used for old Companies typehead/bloodhound
// to remove with autocomplete_companies

const CreateTemplate = ({ createCompanyUrl }) => (
  <footer className="soft-half--ends text--center bordered--top">
    {createCompanyUrl ? (
      <a
        href={createCompanyUrl}
        className="js-create-company"
        target="_blank"
        rel="noopener noreferrer"
      >
        {I18n.t('web.companies.add_new_company')}
      </a>
    ) : (
      <a className="js-create-company">
        {I18n.t('web.companies.add_new_company')}
      </a>
    )}
  </footer>
);

export default CreateTemplate;
