import type { CustomColors } from '@hivebrite/design-system/Theme';

import type { Colors } from 'hb-react/types/network';

export const convertNetworkColorsToFrontofficeTheme = ({
  fo_background_color: backgroundColor,
  header,
  header_links: headerLink,
  links,
  outline_navigation: outlineMenu,
  button_primary: primary,
}: Colors = {}): CustomColors<string> => ({
  backgroundColor,
  header,
  headerLink,
  links,
  outlineMenu,
  primary,
});
