/* eslint-disable no-undef */
/* eslint-disable jquery/no-attr */
/* eslint-disable jquery/no-class */
/* eslint-disable jquery/no-closest */
/* eslint-disable jquery/no-data */
/* eslint-disable jquery/no-each */
/* eslint-disable jquery/no-fade */
/* eslint-disable jquery/no-find */
/* eslint-disable jquery/no-parent */
/* eslint-disable jquery/no-parents */
/* eslint-disable jquery/no-submit */
/* eslint-disable jquery/no-text */
/* eslint-disable jquery/no-val */
import { createRoot } from 'react-dom/client';

import { getUrlParameter } from 'hb-react/frontoffice/utils/url';
import Loader from 'hb-react/shared/components/Loader';
import * as mobileDeviceFromUserAgent from 'helpers/mobileDeviceFromUserAgent';

const initInputs = (namespace = '') => {
  $(`select:not([data-type=browser-default])`, namespace).select2();

  $(document).on('click', `${namespace} .input.select label`, () => {
    const $select = $(this).siblings('select');

    $select.select2('open');
  });

  $(`select:not([data-type=browser-default])`, namespace).change();
};

const findLabelFromAttributeName = (attr, $elem) => {
  const $input = $elem.find(`[id][name*=${attr}]`);
  const $label = $input.parents('form').find(`label[for=${$input.attr('id')}]`);

  if ($elem[0] === document.body) {
    return attr.replace(/_/g, ' ');
  }
  if ($input.length > 0 && $label.length > 0) {
    return $label.text();
  }

  return findLabelFromAttributeName(attr, $elem.parent());
};

const composeErrorText = ($elem, jqxhr) => {
  let json;
  try {
    json = JSON.parse(jqxhr.responseText);
  } catch (_e) {
    json = null;
  }

  let errorText = '';

  if (!json || !json.errors || !json.errors_full_messages) {
    return errorText;
  }

  if (json.errors_full_messages) {
    errorText += json.errors_full_messages.join(', ');
  } else if (Array.isArray(json.errors)) {
    errorText += json.errors.join(', ');
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const [attr, errors] of Object.entries(json.errors)) {
      const label = findLabelFromAttributeName(attr, $elem);

      errorText += ` ${label}: ${errors.join(', ')}.`;
    }
  }

  return errorText;
};

const remoteSave = (e) =>
  new Promise((resolve, reject) => {
    const $input = $(e.target);
    const notifBottom = $input.data('notif') === 'bottom';

    if ($input.data('remote-form') !== 'ignore') {
      const $form = $input.closest('form');

      $form.submit();

      let savingClass = 'label-remote label-remote--saving';

      if (notifBottom) {
        savingClass += ' display-bottom';
      }

      const $notif = $('<span>')
        .addClass(savingClass)
        .text(I18n.t('actions.saving'));

      $input.before($notif);

      $form
        .on('ajax:success', () => {
          $notif.removeClass('label-remote--saving');
          $notif.text(I18n.t('notices.successfully_saved'));
          $notif.addClass('label-remote--success');
          setTimeout(() => {
            $notif.fadeOut('slow', () => $notif.remove());
          }, 2000);
          resolve();
        })
        .on('ajax:error', (_e, jqxhr) => {
          const errorText = composeErrorText($input, jqxhr);

          $notif.removeClass('label-remote--saving');
          $notif.text(`${I18n.t('notices.error_occured')}. ${errorText}`);
          $notif.addClass('label-remote--error');
          setTimeout(() => {
            $notif.fadeOut('slow', () => $notif.remove());
          }, 6000);
          reject();
        });
    }
  });

const initRemoteForm = (namespace = '') => {
  $(
    `${namespace} [data-behavior=auto-submit-form] input, ${namespace} [data-behavior=auto-submit-form] textarea, ${namespace} [data-behavior=auto-submit-form] select`,
  ).change(function () {
    const $form = $(this).closest('form');

    $('body').prepend('<div class="js-global-loader"/>');

    createRoot($('.js-global-loader')[0]).render(
      React.createElement(Loader, { full: true }),
    );

    $form.submit();
  });

  $(document).on(
    'change',
    `${namespace} [data-behavior=remote-form] input, ${namespace} [data-behavior=remote-form] textarea, ${namespace} [data-behavior=remote-form] select`,
    (e) => {
      remoteSave(e);
    },
  );
};

// Exposed for old HAML template compatibility - remove if not used anymore
window.AlumniConnect.Helpers.JS = {
  getUrlParameter,

  isMobile: mobileDeviceFromUserAgent,

  initInputs,

  // Init remote form utils
  remoteSave,
  initRemoteForm,
};
