import { sanitizeUrl } from '@braintree/sanitize-url';
import DOMPurify from 'dompurify';

// eslint-disable-next-line jquery/no-ready, no-undef
$(() => {
  // Listen to message from popup window
  const eventMethod = window.addEventListener
    ? 'addEventListener'
    : 'attachEvent';
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  eventer(
    messageEvent,
    (e) => {
      const key = e.message ? 'message' : 'data';
      const data = e[key];

      if (data && data.match && data.match(/redirect_url:/g)) {
        // Remove potential <script> tags
        const url = DOMPurify.sanitize(data.split('redirect_url:')[1]);
        // Return 'about:blank' if the url is not valid
        const sanitizedUrl = sanitizeUrl(url);

        if (sanitizedUrl !== 'about:blank') {
          window.location.href = url;
        }
      }
    },
    false,
  );
});
