import { createElement } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

class ReactRenderer {
  constructor(klass, container) {
    this.klass = klass;
    this.container = container;
    this.props = {};
    this.component = null;
  }

  replaceProps(props, callback) {
    this.props = {};
    this.setProps(props, callback);
  }

  setProps(partialProps, callback) {
    if (this.klass == null) {
      // eslint-disable-next-line no-console
      console.warn(
        'setProps(...): Can only update a mounted or ' +
          'mounting component. This usually means you called setProps() on ' +
          'an unmounted component. This is a no-op.',
      );

      return;
    }

    Object.assign(this.props, partialProps);

    const element = createElement(this.klass, {
      ...this.props,
      onMounted: this.handleMounted,
    });

    this.component = createRoot(this.container).render(element);

    callback?.();
  }

  handleMounted = (component) => {
    const { onMounted } = this.props;

    if (component) {
      this.component = component;
    }

    if (onMounted) {
      onMounted(component);
    }
  };

  unmount() {
    ReactDOM.unmountComponentAtNode(this.container);
    this.klass = null;
  }
}

export default ReactRenderer;
